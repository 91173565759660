import emptyNode from '@ui/MarkdownEditor/emptyNode';
import { ImageAlignValues } from '@ui/MarkdownEditor/enums';
import type {
  ImageBlock,
  ImageBlockMdNode,
  ParagraphElement,
  ImageBareMdNode,
  ParagraphMdNode,
} from '@ui/MarkdownEditor/types';

export const deserialize = (
  node: ImageBlockMdNode,
  deeper: (node: ImageBlockMdNode) => [ParagraphElement],
): ImageBlock => {
  const { src, alt, title, align, width } = node || {};
  const { border = false, caption } = node.data?.hProperties || {};

  return {
    type: 'image-block',
    border,
    url: src,
    ...(align && { align: ImageAlignValues[align as keyof typeof ImageAlignValues] || '' }),
    ...(!!alt && { alt }),
    ...(!!caption && { caption }),
    ...(!!title && { title }),
    ...(width && { width }),
    children: node.children.length ? deeper(node) : [emptyNode()],
  };
};

export const serialize = (
  node: ImageBlock,
  deeper: (node: ImageBlock) => [ParagraphMdNode],
  { renderingLibrary },
): ImageBareMdNode | ImageBlockMdNode => {
  const { align, border = false, isInline, width, url, alt, title, ...image } = node;

  if (isInline) {
    return {
      ...image,
      type: 'image',
      alt,
      title,
      url,
    } as ImageBareMdNode;
  }

  const captionNode = {
    type: 'paragraph',
    children: deeper(node),
  };
  const captionString = renderingLibrary.mdx(captionNode).trim();

  return {
    type: 'image-block',
    src: url,
    ...(align && { align }),
    ...(!!alt && { alt }),
    ...(!!title && { title }),
    children: deeper(node),
    data: {
      hName: 'img',
      hProperties: {
        border,
        src: url,
        ...(align && { align }),
        ...(!!alt && { alt }),
        ...(!!captionString && { caption: captionString }),
        ...(!!title && { title }),
        ...(width && { width }),
      },
    },
  } as ImageBlockMdNode;
};
